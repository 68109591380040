@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Signika&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: "Inter", sans-serif !important;
}

/* ScrollBar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: #1869a9;
  border-radius: 10px;
}

.secondary-heading {
  font-family: "Signika", sans-serif !important;
}

.img .ant-image-img {
  width: 100% !important;
  height: 100% !important ;
}

.ant-drawer .ant-drawer-header {
  display: none !important;
}

.ant-drawer .ant-drawer-body {
  padding: 0px !important;
}
.ant-drawer-right > .ant-drawer-content-wrapper {
  width: 100% !important;
}

.input .ant-input {
  width: 100%;
  height: 100%;
}

.bg_happy_customer {
  background-image: url("/public/Images/happy-customer.png");
  background-size: cover !important;
  background-position: cover !important;
  background-repeat: no-repeat !important;

  /* background: no-repeat; */
}

.home-bg {
  background-image: url("/public/Images/home-bg.png");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 100%;
}

.house_img {
  background-image: url("/public/Images/house-1.png");
  background-size: cover !important;
  background-position: cover !important;
  background-repeat: no-repeat !important;
}

.lady_Component {
  background-image: url("/public/Images/lady-d.png");
  background-size: cover !important;
  background-position: cover !important;
  background-repeat: no-repeat !important;
}

.lady_Component_m {
  background-image: url("/public/Images/lady-m.png");
  background-size: cover !important;
  background-position: cover !important;
  background-repeat: no-repeat !important;
}

.ReviewsSafeGuard_img {
  background-image: url("/public/Images/R-bg.png");
  background-size: cover !important;
  background-position: cover !important;
  background-repeat: no-repeat !important;
}

.rating_img {
  background-image: url("/public/Images/rating-bg.png");
  background-size: cover !important;
  background-position: cover !important;
  background-repeat: no-repeat !important;
}

.free-resources {
  background-image: url("/public/Images/free-r.png");
  background-size: cover !important;
  background-position: cover !important;
  background-repeat: no-repeat !important;
}
.Finacing-img {
  background-image: url("/public/Images/fin-1.webp");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat;
}

.About-img {
  background-image: url("/public/Images/about.jpg");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat;
}

.select .ant-select-selector {
  height: 50px !important;
  margin-bottom: 20px;
}

.select .ant-select-selection-item {
  display: flex !important;
  align-items: center !important;
}
.select .ant-select .ant-select-arrow {
  margin-bottom: 90px !important;
}

.service-img {
  background-image: url("/public/Images/s-img.png");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat;
}
.service-bg-1 {
  background-image: url("/public/Images/s-bg-1.png");
  background-size: cover !important;
  background-repeat: no-repeat;
}
.services-bg-2 {
  background-image: url("/public/Images/s-bg-2.png");
  background-size: cover !important;
  background-repeat: no-repeat;
}

.note_modal .ant-modal .ant-modal-content {
  padding: 0px !important;
}
.note_modal .ant-modal .ant-modal-close {
  font-size: 18px;
  font-weight: 700px;
  background-color: #aae127;
}
.ant-modal .ant-modal-content {
  border-top-left-radius: 70px !important;
  border-bottom-right-radius: 70px !important;
  padding: 0px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  display: flex !important;
  align-items: center !important;
}

.slick-dots {
  margin-bottom: -50px !important;
}

.slick-initialized .slick-slide {
  width: 357px !important;
}

.slick-dots li button:before {
  content: "";
  width: 24px; /* set the width of the dots */
  height: 4px; /*set the height of the dots */
  display: block;
  border-radius: 2px;
}

.slick-next:before {
  color: black !important;
  font-size: 30px !important;
  opacity: 100;
}

.slick-prev:before {
  color: black !important;
  font-size: 30px !important;
  opacity: 100;
}

.slick-prev {
  left: -30px !important;
}

.slick-next {
  right: -24px !important;
}

.slick-slide div {
  margin: 0px 10px;
}

.slick-slide .slick-cloned {
  width: 100%;
}

.description-container {
  max-height: 2 * 1.2em; /* 2 lines * line height (assuming 1.2em) */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
}

.slick-slide > div {
  margin: 0 10px !important;
}
.slick-list {
  margin: 0 -10px !important;
}

@media screen and (max-width: 575px) {
  .slick-list {
    margin: 0 -10px !important;
  }
}
